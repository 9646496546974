<template>
  <div style="padding-right: 3.4em">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showsnackbar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title
                  style="
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                  "
                >
                  Statements
                  <v-spacer></v-spacer>
                  <v-col md="2">
                    <v-form
                      ref="filterform"
                      lazy-validation
                      @submit.prevent="getSearch"
                    >
                      <v-text-field
                        v-model="searchKey"
                        append-icon="mdi-magnify"
                        outlined
                        placeholder="Search"
                        dense
                        color="black"
                        single-line
                        hide-details
                        @click:append="getSearch"
                      ></v-text-field>
                      <v-btn
                        type="submit"
                        class="hidden-sm-and-down hidden-md-and-up"
                        >Submit</v-btn
                      >
                    </v-form>
                  </v-col>
                  <v-dialog v-model="filter" max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        outlined
                        color="primary"
                        @click="fliter = true"
                        dense
                      >
                        Filtered Search
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="headline grey lighten-2">
                        Filtered Search
                      </v-card-title>

                      <v-card-text>
                        <form
                          ref="bthfilter"
                          lazy-validation
                          @submit.prevent="getSearch"
                          class="pt-4"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="company"
                            label="Company"
                          ></v-text-field>

                          <!-- <v-text-field
                            outlined
                            dense
                            v-model="description"
                            label="Description"
                          ></v-text-field> -->

                          <v-text-field
                            outlined
                            dense
                            v-model="phone"
                            label="Phone"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            dense
                            v-model="invoiceID"
                            label="Invoice ID"
                          ></v-text-field>
                          <v-btn
                            type="submit"
                            class="hidden-sm-and-down hidden-md-and-up"
                            >Submit</v-btn
                          >
                        </form>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="error" @click="closeFilter">
                          Clear
                        </v-btn>
                        <v-btn color="primary" class="mr-4" @click="getSearch">
                          Search
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-title>
                <v-progress-linear
                  :active="isLoading"
                  indeterminate
                  absolute
                  bottom
                  color="primary"
                ></v-progress-linear>
              </v-card>
              <v-card class="mb-4">
                <v-card-text
                  style="
                    padding: 0px !important;
                    padding-top: 10px !important;
                    height: 4em !important;
                  "
                >
                  <v-layout wrap style="align-items: center" justify-center>
                    <v-flex xs6 sm6 md6></v-flex>
                    <v-flex xs6 sm6 md6>
                      <v-layout wrap justify-center>
                        <v-flex xs6 sm6 md4 text-right pt-2
                          >Items Per Page</v-flex
                        >
                        <v-flex xs6 sm6 md2>
                          <v-select
                            solo
                            flat
                            v-model="itemsPerPage"
                            :items="ItemsCount"
                            dense
                            required
                          ></v-select>
                        </v-flex>
                        <v-flex
                          xs6
                          sm6
                          md3
                          text-center
                          pt-2
                          style="color: black"
                        >
                          {{ pageStart }}-{{ pageEnd }} of {{ count }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-right>
                          <span v-if="page > 1">
                            <v-icon @click="prevpage" color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-center>
                          <!-- <v-text-field
                              v-model="page"
                              color="#26af82"
                              min="1"
                              :dense="true"
                              :max="totalpages"
                              type="number"
                              class="centered-input"
                            ></v-text-field> -->
                          {{ page }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-left>
                          <span v-if="page < totalpages">
                            <v-icon @click="nextpage" color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-data-table
                  bordered
                  dense
                  :headers="headers"
                  :items="customers"
                  :items-per-page="itemsPerPage"
                  hide-default-footer
                  @click:row="rowClicked"
                  class="row-pointer tableValue"
                >
                  <!-- @click:row="rowClicked" -->
                  <template v-slot:item.invoiceDate="{ item }">
                    <span>{{ ChangeFormateDate(item.invoiceDate) }}</span>
                  </template>
                  <template v-slot:item.invoiceStatus="{ item }">
                    <span
                      v-if="
                        item.invoiceStatus == 'OPEN' &&
                        dateDifference(item.invoiceDate) <= 60
                      "
                      style="color: #fa8072"
                      >{{ dateDifference(item.invoiceDate) }} -
                      {{ item.invoiceStatus }}</span
                    >
                    <span
                      v-if="
                        item.invoiceStatus == 'OPEN' &&
                        dateDifference(item.invoiceDate) > 60 &&
                        dateDifference(item.invoiceDate) <= 90
                      "
                      style="color: #dc143c"
                      >{{ dateDifference(item.invoiceDate) }} -
                      {{ item.invoiceStatus }}</span
                    >
                    <span
                      v-if="
                        item.invoiceStatus == 'OPEN' &&
                        dateDifference(item.invoiceDate) > 90 &&
                        dateDifference(item.invoiceDate) <= 120
                      "
                      style="color: #ff0000"
                      >{{ dateDifference(item.invoiceDate) }} -
                      {{ item.invoiceStatus }}</span
                    >
                    <span
                      v-if="
                        item.invoiceStatus == 'OPEN' &&
                        dateDifference(item.invoiceDate) > 120
                      "
                      style="color: #800000"
                      >{{ dateDifference(item.invoiceDate) }} -
                      {{ item.invoiceStatus }}</span
                    >

                    <span
                      v-else-if="item.invoiceStatus == 'PAID'"
                      style="color: #008000"
                    >
                      {{ item.invoiceStatus }}</span
                    >
                    <span
                      v-else-if="item.invoiceStatus == 'PARTIAL'"
                      style="color: #fdee00"
                    >
                      {{ item.invoiceStatus }}</span
                    >
                  </template>
                  <template v-slot:item.invoiceID="{ item }">
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-align-center>
                        <!-- <router-link tag='span' :to="'/Order/'+item._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderID }}</v-btn>
                    </router-link> -->
                        <span v-if="item.invoiceID" style="color: darkblue">{{
                          item.invoiceID
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </template>
                  <template v-slot:item.orderId="{ item }">
                    <v-layout
                      wrap
                      justify-center
                      @click.stop="orderClicked(item)"
                    >
                      <v-flex xs12 text-align-center>
                        <!-- <router-link tag='span' :to="'/Order/'+item._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderID }}</v-btn>
                    </router-link> -->
                        <span v-if="item.orderId" style="color: darkblue">{{
                          item.orderId.orderID
                        }}</span>
                      </v-flex>
                    </v-layout>
                    <!-- <span style="color: darkblue" v-if="item.orderId">{{
                      item.orderId.orderID
                    }}</span> -->
                    <!-- <router-link v-if="item.orderId" tag='span' :to="'/Order/'+item.orderId._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderId.orderID }}</v-btn>
                    </router-link> -->
                  </template>
                  <template v-slot:item.billToCompany="{ item }">
                    <span
                      v-if="item.billToCompany"
                      v-html="item.billToCompany.slice(0, 15)"
                    >
                      <!-- {{ item.productDescription.slice(0, 40) }} -->..
                    </span>
                  </template>
                  <template v-slot:item.productDescription="{ item }">
                    <span
                      v-if="item.productDescription"
                      v-html="item.productDescription.slice(0, 30)"
                    >
                      <!-- {{ item.productDescription.slice(0, 40) }} -->..
                    </span>
                  </template>
                  <template v-slot:item.agentCode="{ item }">
                    <span v-if="item.created_by"
                      >{{ item.created_by.agentCode
                      }}<span v-if="item.updated_by"
                        >/{{ item.updated_by.agentCode }}</span
                      ></span
                    >
                  </template>
                  <template v-slot:item.totalAmount="{ item }">
                    <span>${{ item.totalAmount }}</span>
                  </template>
                  <template v-slot:item.balance="{ item }">
                    <span>${{ item.balance }}</span>
                  </template>
                  <!-- <template v-slot:cell(invoiceStatus)="data">
                    <span v-if="data.item.invoiceStatus=='paid'" style="color:'green'">{{
                      data.item.status
                    }}</span>
                    <span v-else>-</span>
                  </template> -->
                  <template v-slot:item.actions="{ item }">
                    <span v-if="item.orderId">
                      <v-btn
                        v-if="item.orderId.attachments.length > 0"
                        icon
                        @click.stop="openImg(item.orderId)"
                      >
                        <v-icon small color="primary"> mdi-eye</v-icon>
                      </v-btn>
                      <v-btn v-else icon>
                        <v-icon small color="grey" class="mr-2">
                          mdi-eye-off
                        </v-icon>
                      </v-btn>
                    </span>
                    <span v-else>
                      <v-btn icon>
                        <v-icon small color="grey" class="mr-2">
                          mdi-eye-off
                        </v-icon>
                      </v-btn>
                    </span>
                    <!-- </a
                        > -->
                    <!-- </template> -->

                    <!-- <v-card>
                        <v-list>
                          <v-list-item>
                            <v-img
                              v-if="item.attachments[0]"
                              :src="
                                baseURL + '/file/get/' + item.attachments[0]
                              "
                              height="200px"
                              width="auto"
                              contain
                            ></v-img>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu> -->
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                          @click="delConfirmation(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>

                      <v-card>
                        <v-card-title class="headline grey lighten-2">
                          Delete Confirmation
                        </v-card-title>

                        <v-card-text>
                          <span style="font-size: 16px; font-weight: 600"
                            >Please confirm that you want to Remove this Invoice
                            details ?
                          </span>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn color="primary" @click="item.delete = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            color="error"
                            class="mr-4"
                            @click="deleteInvoice(item)"
                          >
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <!-- <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      :items-per-page-options="itemsperpage"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                  </template> -->
                </v-data-table>
                <v-card-text>
                  <v-divider></v-divider>
                  <v-layout wrap class="pt-4">
                    <v-flex xs9 md9 text-right>
                      <span class="black--text"><b>Total Balance</b></span>
                    </v-flex>
                    <v-flex
                      v-if="balAmount"
                      class="font-weight-medium black--text"
                      xs3
                      md1
                      text-right
                      pl-2
                    >
                      ${{ balAmount }}
                    </v-flex>
                    <v-flex v-else xs3 md1 text-right pl-2>
                      <v-progress-circular
                        :size="20"
                        :width="2"
                        color="grey"
                        indeterminate
                      ></v-progress-circular>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      style="position: fixed; padding-top: 5%"
      right
      width="55"
    >
      <v-list nav dense>
        <v-list-item link to="" title="Excel">
          <v-list-item-icon>
            <vue-excel-xlsx
              :data="customers"
              :columns="columns"
              :filename="'Invoice List'"
              ><v-icon color="primary">mdi-file-excel</v-icon></vue-excel-xlsx
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <v-list-item link title="Pdf">
          <v-list-item-icon>
            <a
              v-if="searchKey || company || phone || invoiceID"
              :href="
                'http://13.127.171.21/pdf/generate/Invoices_Report/?limit=' +
                itemsPerPage +
                '&page=' +
                page +
                '&pdfType=search' +
                '&keyword=' +
                searchKey +
                '&company=' +
                company +
                '&phone=' +
                phone +
                '&invoiceID=' +
                invoiceID +
                '&invoiceType=statement'
              "
              class="downlink"
            >
              <v-icon color="primary">mdi-file-pdf</v-icon></a
            >
            <a
              v-else
              :href="
                'http://13.127.171.21/pdf/generate/Invoices_Report/?limit=' +
                itemsPerPage +
                '&page=' +
                page +
                '&invoiceType=statement'
              "
              class="downlink"
            >
              <v-icon color="primary">mdi-file-pdf</v-icon></a
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      delete: false,
      isLoading: false,
      filter: false,
      headers: [
        {
          text: "Inv.#",
          align: "start",
          value: "invoiceID",
          width: "6%",
          sortable: false,
        },
        {
          text: "Ord.#",
          align: "start",
          value: "orderId",
          width: "7%",
          sortable: false,
        },
        { text: "Date", value: "invoiceDate", sortable: true, width: "8%" },
        {
          text: "Company",
          value: "billToCompany",
          width: "12%",
          sortable: false,
        },
        {
          text: "Description",
          value: "productDescription",
          width: "20%",
          sortable: false,
        },
        { text: "Phone", value: "phone", width: "12%", sortable: false },
        {
          text: "Status",
          value: "invoiceStatus",
          width: "8%",
          sortable: false,
        },
        {
          text: "Amt",
          value: "totalAmount",
          width: "6%",
          align: "end",
          sortable: false,
        },
        {
          text: "Bal",
          value: "balance",
          width: "6%",
          align: "end",
          sortable: false,
        },
        { text: "Agent", value: "agentCode", width: "7%", sortable: false },
        { text: "Actions", value: "actions", sortable: false, width: "10%" },
      ],
      columns: [
        { label: "Inv.#", field: "invoiceID" },
        { label: "Ord.#", field: "orderId.orderID" },
        {
          label: "Date",
          field: "invoiceDate",
          dataFormat: this.ChangeFormateDate,
        },

        { label: "Company", field: "billToCompany" },

        { label: "Description", field: "productDescription" },
        { label: "Phone", field: "phone" },
        { label: "Status", field: "status" },
        { label: "Amt", field: "totalAmount" },
        { label: "Bal", field: "balance" },
        // { label: "Agent", value: "agentCode" },
      ],
      searchKey: null,
      keyword: null,
      company: null,
      description: null,
      phone: null,
      invoiceID: null,
      mini: true,
      customers: [],
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      itemsPerPage: 20,
      page: 1,
      totalpages: 0,
      ItemsCount: [20, 30, 50, 100],
      showsnackbar: false,
      msg: "",
      timeout: 2000,
      batchPrint: false,
      start: null,
      until: null,
      balAmount: null,
    };
  },
  beforeMount() {
    this.getInvoices();
    this.balanceAmt();
  },
  watch: {
    // searchKey: function () {
    //   this.getSearch();
    // },
    // company: function () {
    //   this.getSearch();
    // },
    // address: function () {
    //   this.getSearch();
    // },
    phone: function () {
      var str = this.phone;
      var ind;
      if (str.length == 3) {
        ind = str.substring(0, 3);
        this.phone = ind + "-";
      }
      if (str.length == 8) {
        ind = str.substring(0, 7);
        this.phone = ind + "-";
      }
    },

    // invoiceID: function () {
    //   this.getSearch();
    // },
    itemsPerPage: function () {
      if (
        this.searchKey ||
        this.company ||
        this.address ||
        this.phone ||
        this.email ||
        this.nature ||
        this.customerID
      ) {
        this.getSearch();
      } else this.getInvoices();
    },
    // page: function () {
    //   if (this.page > 0) this.getInvoices();
    // },
  },
  methods: {
    prevpage() {
      if (this.page > 1) {
        if (
          this.searchKey ||
          this.company ||
          this.address ||
          this.phone ||
          this.email ||
          this.nature ||
          this.customerID
        ) {
          this.page--;
          this.getSearch();
        } else {
          this.page--;
          this.getInvoices();
        }
      }
    },
    nextpage() {
      if (this.page < this.totalpages) {
        if (
          this.searchKey ||
          this.company ||
          this.address ||
          this.phone ||
          this.email ||
          this.nature ||
          this.customerID
        ) {
          this.page++;
          this.getSearch();
        } else {
          this.page++;
          this.getInvoices();
        }
      }
    },
    batchPrintdata() {
      this.batchPrint = false;

      window.open(
        "http://13.127.171.21/pdf/generate/Invoice_Batch_Details/?start=" +
          this.start +
          "&end=" +
          this.until,
        "_blank"
      );
    },
    getInvoices() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/invoices/statement",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // status:"active",
          limit: this.itemsPerPage,
          page: this.page,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.customers = response.data.data;
          this.count = response.data.totalLength;
          this.totalpages = Math.ceil(
            response.data.totalLength / this.itemsPerPage
          );

          if (response.data.length > 0) {
            this.pageStart = (response.data.page - 1) * response.data.limit + 1;
            this.pageEnd = this.pageStart + response.data.length - 1;
          }
          this.printData();
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    printData() {
      axios({
        method: "GET",
        url: "/invoice_batch/start_end",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.start = response.data.start;
            this.until = response.data.end;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    balanceAmt() {
      axios({
        method: "POST",
        url: "/invoices/balance",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.balAmount = response.data.balance;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    closeFilter() {
      this.filter = false;
      this.company = "";
      this.phone = "";
      this.invoiceID = "";
      this.getInvoices();
    },
    getSearch() {
      if (this.keyword == null) {
        this.page = 1;
        this.keyword = this.searchKey;
      }
      if (this.keyword != this.searchKey) {
        this.page = 1;
        this.keyword = this.searchKey;
      }

      this.filter = false;
      this.isLoading = true;
      var data = {};
      data.limit = this.itemsPerPage;
      data.page = this.page;
      if (this.searchKey != "") data.keyword = this.searchKey;
      else {
        this.page = 1;
        this.getInvoices();
        this.isLoading = false;
      }
      data.company = this.company;
      data.description = this.description;
      data.phone = this.phone;
      data.invoiceID = this.invoiceID;
      data.invoiceType = "statement";
      axios({
        method: "POST",
        url: "/invoice/search",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.isLoading = false;
          if (response.data.status) {
            this.appLoading = false;
            // if (this.searchKey == "" || this.searchKey == null) {
            //   this.getInvoices();
            // } else {
            this.customers = response.data.data;
            this.count = response.data.totalLength;
            this.totalpages = Math.ceil(
              response.data.totalLength / this.itemsPerPage
            );

            if (response.data.length > 0) {
              this.pageStart =
                (response.data.page - 1) * response.data.limit + 1;
              this.pageEnd = this.pageStart + response.data.length - 1;
            }
            this.company = "";
            this.phone = "";
            this.invoiceID = "";
            // }
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.appLoading = false;
          console.log(err);
        });
    },
    delConfirmation() {
      this.delete = true;
    },
    deleteInvoice(item) {
      this.appConfirmation = false;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/invoice/delete/" + item._id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.getInvoices();
            this.delete = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          } else {
            this.delete = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showsnackbar = true;
        });
    },
    dateDifference(date) {
      // var date1 = new Date().toISOString().substr(0, 10)
      // const _MS_PER_DAY = 1000 * 60 * 60 * 24;

      // Discard the time and time-zone information.

      const utc1 = new Date().toISOString().substr(0, 10);

      if (date) {
        var newdateFormat = date.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }

        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
      }

      const utc2 = year + "-" + month + "-" + day;

      return Math.floor(
        (this.parseDate(utc1) - this.parseDate(utc2)) / (1000 * 60 * 60 * 24)
      );
    },

    parseDate(str) {
      var mdy = str.split("-");
      return new Date(mdy[0], mdy[1] - 1, mdy[2]);
    },

    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }

        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];

        return month + "/" + day + "/" + year;
      }
    },
    openImg(item) {
      item.imgFile = false;

      window.open(
        "http://13.127.171.21/file/get/" + item.attachments[0],
        "_blank"
      );
    },
    rowClicked(item) {
      this.$router.push("/Invoice/" + item._id);
    },
    orderClicked(item) {
      if (item.orderId) this.$router.push("/Order/" + item.orderId._id);
    },
  },
};
</script>
<style >
tbody tr:nth-of-type(odd) {
  background-color: rgba(28, 133, 219, 0.05);
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
a {
  text-decoration: none !important;
}
.v-data-table td,
.v-data-table th {
  padding: 0 10px !important;
}
.v-data-table td {
  font-size: 0.7em;
}
</style>



